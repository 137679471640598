<template>
	<v-layout justify-center align-center row wrap v-bind="$attrs">
		<OpenPreviewButton v-if="!conditionalDisplay.isMobileApp" v-model="document" :selected-folder="selectedFolder" :version="documentVersion" simple-button-display open-in-new-tab />
		<DownloadButton v-model="model" simple-button-display :version="documentVersion" />
		<SignatureButtons v-if="!conditionalDisplay.isMobileApp" v-model="model" simple-button-display />
		<CounterButton v-model="model" simple-button-display />
		<NodeSurveyButtons v-if="!conditionalDisplay.isMobileApp" v-model="model" simple-button-display />
		<PrintButton v-if="!conditionalDisplay.isMobileApp" v-model="model" simple-button-display />
		<RestoreFromTrashButton v-model="model" simple-button-display />
		<DeleteButton v-model="model" :selected-folder="selectedFolder" simple-button-display />
	</v-layout>
</template>

<script>
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

export default {
	name: 'DetailsToolBar',
	components: {
		DeleteButton: () => ({
			component: import('@/components/Documents/Actions/Delete/DeleteButton')
		}),
		DownloadButton: () => ({
			component: import('@/components/Documents/Actions/Download/DownloadButton')
		}),
		OpenPreviewButton: () => ({
			component: import('@/components/Documents/Actions/Open/OpenPreviewButton')
		}),
		NodeSurveyButtons: () => ({
			component: import('@/components/Documents/Actions/Survey/NodeSurveyButtons')
		}),
		RestoreFromTrashButton: () => ({
			component: import('@/components/Documents/Actions/Trash/RestoreFromTrashButton')
		}),
		CounterButton: () => ({
			component: import('@/components/Documents/Counter/CounterButton')
		}),
		SignatureButtons: () => ({
			component: import('@/components/Documents/Signature/SignatureButtons')
		}),
		PrintButton: () => ({
			component: import('@/components/Documents/Actions/Print/PrintButton')
		})
	},
	mixins: [DocumentsManagerModuleGuard],
	props: {
		documentVersion: {
			type: Object,
			required: false,
			default: null
		},
		value: {
			type: Array,
			required: false,
			default: () => []
		},
		selectedFolder: {
			type: Object,
			required: false,
			default: null
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		items: function () {
			return this.model.length > 1 ? this.model : null
		},
		item: function () {
			return this.model.length == 1 ? this.model[0] : null
		},
		folder: function () {
			return this.item && this.item.is_folder ? this.item : null
		},
		document: {
			get: function () {
				return this.item && this.item.is_document ? this.item : null
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		version: function () {
			return this.document && this.documentVersion ? this.documentVersion : null
		},
		folders: function () {
			return this.items && this.items.every(item => !item.is_folder) ? this.items : null
		},
		documents: function () {
			return this.items && this.items.every(item => item.is_folder) ? this.items : null
		},
		selectionSize: function () {
			let result = 0
			if (!this.folders) {
				if (this.document) {
					result = this.document.size
				} else if (this.documents) {
					result = this.documents.map(item => item.size).reduce((sum, size) => sum + size, 0)
				}
			}
			return result > 0 ? result : null
		}
	}
}
</script>
